import { put, call, select } from 'redux-saga/effects';

import { UnverifiedEmailLoginError } from '@epi-constants/login';

import { goToReturnUrl } from '../navigation';
import { userLogin } from '../../repositories/LoginRepository';
import { routerPaths } from '../../constants/routerPaths';
import { SetAuthorizationData } from '../../helpers/AuthorizeHelper';
import {
  ApiConstants,
  NavigationConstants,
  ApplicationConstants
} from '../../constants/actions';
import * as overlaysActions from '../../actions/overlays';
import { hasOrders as hasOrdersRepo } from '../../repositories/UserRepository';

export function* ApiLogin({ payload, isAutoLogin, autoRedirect }) {
  try {
    const loginResponse = yield call(userLogin, payload);

    yield call(SetAuthorizationData, loginResponse);
    const hasOrders = yield call(hasOrdersRepo);
    yield put({
      type: `${ApiConstants.GET_HAS_ORDERS}_LOADED`,
      payload: { hasOrders }
    });
    if (payload.rememberMe === true) {
      yield put({
        type: `${ApplicationConstants.REMEMBER_ME}_SAVE`
      });
    }

    if (autoRedirect) {
      yield call(goToReturnUrl);
    }
    yield put({ type: `${ApplicationConstants.CLEAN_COMPANY_EXISTS_INFO}` });

    if (!isAutoLogin) {
      yield put(
        overlaysActions.showNotification(
          { message: 'messages.successful_login' },
          'success'
        )
      );
    }
    yield put({
      type: `${ApiConstants.CLEAR_API_ERROR}`
    });
  } catch (error) {
    if (error.content.errors[0].code === UnverifiedEmailLoginError) {
      yield put({
        type: `${ApiConstants.LOGIN_ACTION}_UNVERIFIED`,
        payload: { ...error, username: payload.username },
        isError: true
      });
    } else {
      yield put({
        type: `${ApiConstants.LOGIN_ACTION}_FAILURE`,
        payload: error,
        isError: true
      });
    }
  }
}

export function* GoToLoginPage() {
  const state = yield select();
  if (state.login.isAuthenticated) {
    yield put(
      overlaysActions.showNotification(
        { message: 'messages.already_logged_in' },
        'success'
      )
    );
    yield call(goToReturnUrl);
    return;
  }
  yield put({
    type: `${NavigationConstants.GO_TO_PAGE}`,
    url: routerPaths.login
  });
}
