import { createSelector } from 'reselect';
import { isString, some } from 'lodash';
import { SystemRoles } from '@epi-constants/roles';

export const getLoginState = state => state.login;
export const getIsAuthenticated = state => state.login.isAuthenticated;
export const getHasOrders = state => state.login.hasOrders;
export const isHasOrdersFetchedSelector = state =>
  state.login.isHasOrdersFetched;
export const isFetching = state => state.login.isFetching;
export const getUserRoles = state => state.login.roles;
export const getIsUserUnverified = state => state.login.isUserUnverified;
export const getUsername = state => state.login.username;

export const hasRole = roles =>
  createSelector(getUserRoles, userRoles => {
    if (!roles || roles.length <= 0) {
      return true;
    }
    return some(isString(roles) ? [roles] : roles, item =>
      userRoles.includes(item)
    );
  });

export const isSignatorySelector = createSelector(getUserRoles, roles =>
  roles.includes(SystemRoles.Signatory)
);
